import React from "react";
import { SocialCardType } from "../types";
import "../css/compStyles/_socialCard.scss";

export default function SocialCard({ social }: SocialCardType) {
  return (
    <div className="social-card">
      <div className="icon">
        <a
          href={social.title === "Mail" ? "mailto:" + social.href : social.href}
        >
          {social.icon}
        </a>
      </div>
      <div className="social-card-content">
        {/* Title */}
        <h2>{social.title}</h2>
        {/* Description */}
        <h4>{social.content}</h4>
      </div>
    </div>
  );
}
