import React from "react";
import "../css/compStyles/_contact.scss";
import { socialsType } from "../types";
import { IconMail } from "@tabler/icons-react";
import { IconBrandLinkedin } from "@tabler/icons-react";
import { IconBrandGithub } from "@tabler/icons-react";
import SocialCard from "./SocialCard";

export default function Contact() {
  const socials : socialsType = [
    {
      title: "Mail",
      content: "d_jurma@yahoo.com",
      icon: <IconMail  height={32} width={32}/>,
      href:"d_jurma@yahoo.com",
    },
    {
        title: "Linkedin",  
        content: "Daniel Jurma",
        icon: <IconBrandLinkedin height={32} width={32}/>,
        href:"https://www.linkedin.com/in/daniel-jurma/"
      },
      {
        title: "Github",
        content: "jurmadani",
        icon: <IconBrandGithub  height={32} width={32}/>,
        href:"https://github.com/jurmadani",
      },
  ];

  return (
    <div className="contact">
      <div className="contact-content">
        <h1 className="title">
          Let's collaborate and create amazing things together! 🚀
        </h1>
        <div className="socials">
            {socials.map(social => <SocialCard social={social}/>)}
        </div>
      </div>
    </div>
  );
}
