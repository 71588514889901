import React from "react";
import Header from "./components/Header";
import Hero from "./components/Hero";
import "./css/compStyles/_hero.scss";
import About from "./components/About";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      {/* Header */}
      <Header />
      {/* Hero section */}
      <Hero />
      {/* About */}
      <About />
      {/* Footer */}
      <Footer/>
    </div>
  );
}

export default App;
