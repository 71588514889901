import React from "react";
import "../css/compStyles/_about.scss";
import PixelArtBackgroundBanner from "../assets/pixerlart-banner-background-2.jpeg";
import VerifiedIcon from "@mui/icons-material/Verified";
import { tabsType } from "../types";
import Feed from "./Feed";
import AboutSection from "./AboutSection";
import Projects from "./Projects";
import Contact from "./Contact";

export default function About() {
  const [tabSelected, setTabSelected] = React.useState(0);
  const tabs: tabsType = [
    {
      title: "Feed",
      id: 0,
      href: "#feed",
    },
    {
      title: "About",
      id: 1,
      href: "#about",
    },
    {
      title: "Projects",
      id: 2,
      href: "#projects",
    },
    {
      title: "Contact",
      id: 3,
      href: "#contact",
    },
  ];

  const handleOnClickTab = (index: number) => {
    setTabSelected(index);
  };

  return (
    <>
      <section className="about" id="about">
        <div className="container">
          <h1 className="about-title">who am I?..</h1>
          <div className="content">
            {/* Profile header: background image + profile picture */}
            <div className="profile">
              <div className="background">
                <img src={PixelArtBackgroundBanner} alt="pixel-art" />
              </div>
              <div className="picture"></div>
            </div>
            {/* User information */}
            <div className="user-information">
              <div className="user-name">
                {/* Name */}
                <h1>Jurma Daniel</h1>
                {/* Verified badge */}
                <VerifiedIcon
                  className="verified-badge"
                  sx={{ color: "#1D9BF0" }}
                />
              </div>
              <div className="user-bio">
                {/* Slogan */}
                <p>
                  Designing with{" "}
                  <i className="italic-paragraph">&lt;style&gt;</i> and Passion.
                </p>

                {/* Current ocupation */}
                <p>
                  Full stack developer. Computer science student. Coffee addict.
                </p>
              </div>
              <div className="user-stats">
                {/* Job position */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="svg-icon"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6 3.75A2.75 2.75 0 018.75 1h2.5A2.75 2.75 0 0114 3.75v.443c.572.055 1.14.122 1.706.2C17.053 4.582 18 5.75 18 7.07v3.469c0 1.126-.694 2.191-1.83 2.54-1.952.599-4.024.921-6.17.921s-4.219-.322-6.17-.921C2.694 12.73 2 11.665 2 10.539V7.07c0-1.321.947-2.489 2.294-2.676A41.047 41.047 0 016 4.193V3.75zm6.5 0v.325a41.622 41.622 0 00-5 0V3.75c0-.69.56-1.25 1.25-1.25h2.5c.69 0 1.25.56 1.25 1.25zM10 10a1 1 0 00-1 1v.01a1 1 0 001 1h.01a1 1 0 001-1V11a1 1 0 00-1-1H10z"
                    clip-rule="evenodd"
                  ></path>
                  <path d="M3 15.055v-.684c.126.053.255.1.39.142 2.092.642 4.313.987 6.61.987 2.297 0 4.518-.345 6.61-.987.135-.041.264-.089.39-.142v.684c0 1.347-.985 2.53-2.363 2.686a41.454 41.454 0 01-9.274 0C3.985 17.585 3 16.402 3 15.055z"></path>
                </svg>
                <p>Employed</p>
                {/* Birthdate */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="svg-icon"
                >
                  <path d="M6.75.98l-.884.883a1.25 1.25 0 101.768 0L6.75.98zM13.25.98l-.884.883a1.25 1.25 0 101.768 0L13.25.98zM10 .98l.884.883a1.25 1.25 0 11-1.768 0L10 .98zM7.5 5.75a.75.75 0 00-1.5 0v.464c-1.179.305-2 1.39-2 2.622v.094c.1-.02.202-.038.306-.051A42.869 42.869 0 0110 8.5c1.93 0 3.83.129 5.694.379.104.013.206.03.306.051v-.094c0-1.232-.821-2.317-2-2.622V5.75a.75.75 0 00-1.5 0v.318a45.645 45.645 0 00-1.75-.062V5.75a.75.75 0 00-1.5 0v.256c-.586.01-1.17.03-1.75.062V5.75zM4.505 10.365A41.377 41.377 0 0110 10c1.863 0 3.697.124 5.495.365C16.967 10.562 18 11.838 18 13.28v.693a3.72 3.72 0 01-1.665-.393 5.222 5.222 0 00-4.67 0 3.722 3.722 0 01-3.33 0 5.222 5.222 0 00-4.67 0A3.72 3.72 0 012 13.972v-.693c0-1.441 1.033-2.716 2.505-2.914zM15.665 14.921a5.22 5.22 0 002.335.551V16.5a1.5 1.5 0 01-1.5 1.5h-13A1.5 1.5 0 012 16.5v-1.028c.8 0 1.6-.183 2.335-.551a3.722 3.722 0 013.33 0c1.47.735 3.2.735 4.67 0a3.722 3.722 0 013.33 0z"></path>
                </svg>
                <p>May 8th</p>
                {/* Website date creation */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="svg-icon"
                >
                  <path d="M5.25 12a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H6a.75.75 0 01-.75-.75V12zM6 13.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V14a.75.75 0 00-.75-.75H6zM7.25 12a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H8a.75.75 0 01-.75-.75V12zM8 13.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V14a.75.75 0 00-.75-.75H8zM9.25 10a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H10a.75.75 0 01-.75-.75V10zM10 11.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V12a.75.75 0 00-.75-.75H10zM9.25 14a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H10a.75.75 0 01-.75-.75V14zM12 9.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V10a.75.75 0 00-.75-.75H12zM11.25 12a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H12a.75.75 0 01-.75-.75V12zM12 13.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V14a.75.75 0 00-.75-.75H12zM13.25 10a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H14a.75.75 0 01-.75-.75V10zM14 11.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V12a.75.75 0 00-.75-.75H14z"></path>
                  <path
                    fill-rule="evenodd"
                    d="M5.75 2a.75.75 0 01.75.75V4h7V2.75a.75.75 0 011.5 0V4h.25A2.75 2.75 0 0118 6.75v8.5A2.75 2.75 0 0115.25 18H4.75A2.75 2.75 0 012 15.25v-8.5A2.75 2.75 0 014.75 4H5V2.75A.75.75 0 015.75 2zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <p>Joined October 2023 </p>
              </div>
            </div>
            <div className="tab-menu">
              <ul role="tablist">
                {tabs.map((tab) => (
                  <li onClick={() => handleOnClickTab(tab.id)}>
                    <a>
                      <span
                        className={
                          tab.id === tabSelected
                            ? "selected-tab"
                            : "non-selected-tab"
                        }
                      >
                        {tab.title}
                      </span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <section
              className={tabSelected === 0 ? "block" : "hidden"}
              id="about"
            >
              <Feed />
            </section>
            <section
              className={tabSelected === 1 ? "block" : "hidden"}
              id="about"
            >
              <AboutSection/>
            </section>
            <section
              className={tabSelected === 2 ? "block" : "hidden"}
              id="projects"
            >
             <Projects/>
            </section>
            <section
              className={tabSelected === 3 ? "block" : "hidden"}
              id="contact"
            >
              <Contact/>
            </section>
          </div>
        </div>
      </section>
    </>
  );
}
