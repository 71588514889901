import React from "react";
import Logo from "../assets/logo.png";
import "../css/compStyles/_header.scss";
import { IconMenu2, IconX } from "@tabler/icons-react";

export default function Header() {
  const [hamburger, setHamburger] = React.useState(false);

  const hamburgerMenu = () => {
    setHamburger(!hamburger);
  };

  const pageUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const navlinks = [
    {
      name: "Home",
      link: "#home",
    },
    {
      name: "About",
      link: "#about",
    },
    {
      name: "Projects",
      link: "#about",
    },
    {
      name: "Contact",
      link: "#about",
    },
  ];

  return (
    <>
      <div className="header">
        {/* Logo */}
        <img src={Logo} className="logo" onClick={pageUp} />
        {/* Navigation anchors */}
        <ul>
          {navlinks.map((item) => (
            <li key={item.name}>
              <a href={item.link}>{item.name}</a>
            </li>
          ))}
          <li onClick={() => hamburgerMenu()}>
            <IconMenu2 className="mobile-menu" width={30} height={30} />
          </li>
        </ul>
      </div>

      {/* mobile nav */}
      <div className={`mobile-nav ${hamburger ? "open-menu" : "closed-menu"}`}>
        <span onClick={() => hamburgerMenu()}>
          <IconX width={30} height={30} />
        </span>
        <ul>
          {navlinks.map((item) => (
            <li key={item.name} onClick={() => hamburgerMenu()}>
              <a href={item.link}>{item.name}</a>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
