import React from "react";
import "../css/compStyles/_aboutSection.scss";
import Signature from "../assets/my-signature.png"

export default function AboutSection() {
  return (
    <div className="about-section">
      <div className="about-section-content">
        {/* Title */}
        <h1>Welcome! 👋</h1>
        {/* Multiple paragraphs */}
        <p>
          I'm Daniel, a digital artisan who derives endless delight from
          fashioning inventive solutions using the magic of code. I specialize
          in crafting cutting-edge mobile and web applications, harnessing the
          power of the latest technologies to create top-tier products that
          captivate and delight the customer.
        </p>
        <p>
          I am a fervent advocate for the enchanting world of creative coding, a
          realm where I empower individuals to unlock the magic of their
          imaginations. My passion lies in not only speaking about this craft
          but also in guiding fellow enthusiasts on the exhilarating journey of
          skill elevation.
        </p>
        <p>
          My claim to fame rests in my ability to conjure whimsical digital
          creations and unravel the mysteries of intricate problems. The marvel
          of being able to open a computer and delve into the infinite expanse
          of knowledge never ceases to amaze me!
        </p>
        <p>
          Feel free to drop a line with a friendly "Hello!" I'm eagerly
          anticipating our conversation!
        </p>
        <img alt="my signature" src={Signature}/>
        {/* Signature */}
      </div>
    </div>
  );
}
