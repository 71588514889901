import React from "react";
import { projectCardType } from "../types";
import "../css/compStyles/_projectCard.scss";
import { IconBrandGithub } from "@tabler/icons-react";

export default function ProjectCard({
  title,
  projectDescription,
  frameworkUsed,
  programmingLanguage,
  projectHighlightImage,
  githubURL,
  timestamp,
}: projectCardType) {
  return (
    <div className="projectCard">
      {/* Image */}
      <img src={projectHighlightImage} alt="project thumbnail" loading="lazy"/>
      <div className="projectCard-content">
        {/* Title */}
        <h1>{title.toUpperCase()}</h1>
        {/* Timestamp */}
        {/* <p className="timestamp">{timestamp}</p> */}
        {/* Project description */}
        <p className="project-description">{projectDescription}</p>
        {/* Tech used */}
        <p className="tech-used">Tech used</p>
        <hr className="break-line" />
        <div className="tech-used-container">
          <div className="tech-content">
            <p>{frameworkUsed}</p>
          </div>
          <div className="tech-content">
            <p>{programmingLanguage}</p>
          </div>
        </div>
        {/* <div className="github-code">
          <a href={githubURL}>
            Check code
            <IconBrandGithub />
          </a>
        </div> */}
      </div>
    </div>
  );
}
