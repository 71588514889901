import React from "react";
import Memoji from "../assets/memoji.png";
import { IconBrandGithub, IconBrandLinkedin } from "@tabler/icons-react";
import "../css/compStyles/_hero.scss"

export default function Hero() {
  const skillsIcons = [
    {
      img: "https://skillicons.dev/icons?i=html,css",
      id: 1,
    },
    {
      img: "https://skillicons.dev/icons?i=js,ts",
      id: 2,
    },
    {
      img: "https://skillicons.dev/icons?i=react,firebase",
      id: 3,
    },
    {
      img: "https://skillicons.dev/icons?i=figma,scss",
      id: 4,
    },
    {
      img: "https://skillicons.dev/icons?i=cpp,java",
      id: 5,
    },
    {
      img: "https://skillicons.dev/icons?i=swift,python",
      id: 6,
    },
  ];
  return (
    <>
      <section id="home" className="hero">
        <div className="container">
          <div className="memoji-container">
            <img src={Memoji} className="memoji-img" />
          </div>
          {/* Title */}
          <h1 className="hero-title">Hi, I'm Daniel 🤟</h1>

          <p className="hero-slogan">
            Transforming ideas into{" "}<br/>
            <span className="hero-slogan-styled">digital products.</span>
          </p>
          <p className="hero-description">
            a <b>Fullstack Developer</b> based in Romania 📍
            <br />I specialize in <b>Mobile & Web</b> Development.
          </p>
          <div className="socials">
            <a href="https://www.linkedin.com/in/daniel-jurma/">
              <IconBrandLinkedin className="icon-brand-linkedin" />
            </a>
            <a href="https://github.com/jurmadani">
              <IconBrandGithub className="icon-brand-github" />
            </a>
          </div>
          <div className="tech-stack">
            <h2>Tech stack</h2>
            <hr className="break-line" />
            <p>
              Embracing new technology to deliver unparalleled efficiency and
              service excellence.
            </p>
            <div className="logos">
              <ul>
                 
                {skillsIcons.map((icon) => (
                  <li>
                    <img src={icon.img} />
                  </li>
                ))}
              </ul>
            </div>
            <h5>and more...</h5>
          </div>
        </div>
      </section>
    </>
  );
}
