import React from "react";
import "../css/compStyles/_projects.scss";
import { projectsType } from "../types";
import ProjectCard from "./ProjectCard";
import SpotifyCloneThumbnail from "../assets/SpotifyClone_Thumbnail.png";
import WhatsappCloneThumbnail from "../assets/WhatsappClone_Thumbnail.png";
import InstagramCloneThumbnail from "../assets/InstagramClone_Thumbnail.png";
import HazzardStudioThumbnail from "../assets/HazzardStudio_Thumbnail.png";
import OrarUPTThumbnail from "../assets/OrarUPT_Thumbnail.jpeg";
import ChatSharpThumbnail from "../assets/ChatSharpThumbnail.webp";
import CoinVueThumbnail from '../assets/CoinVue_Thumbnail.png'
import TwitterCloneThumbnail from '../assets/TwitterClone_Thumbnail.png'

export default function Projects() {
  const projects: projectsType = [
    {
      title: "Coin Vue",
      projectDescription:
        "CoinVue is a Swift & SwiftUI application that allows users to track cryptocurrency prices and trends. It leverages the MVVM architecture, Combine framework, Core Data for data persistence, and the CoinGecko API for real-time cryptocurrency data.",
      frameworkUsed: "SwiftUI",
      programmingLanguage: "Swift",
      githubURL: "https://github.com/jurmadani/CoinVue",
      projectHighlightImage: CoinVueThumbnail,
      timestamp:"January 2024"
    },
    {
      title: "Twitter Clone",
      projectDescription:
        "This project is a Twitter clone developed using Xcode, Swift, SwiftUI, and SF Symbols. The goal of the project is to replicate key UI features of the ex-Twitter platform.",
      frameworkUsed: "SwiftUI",
      programmingLanguage: "Swift",
      githubURL: "https://github.com/jurmadani/TwitterClone",
      projectHighlightImage: TwitterCloneThumbnail,
      timestamp:"December 2023"
    },
    {
      title: "Spotify Clone",
      projectDescription:
        "This project is an Spotify clone developed using React.js and TypeScript. It includes features such as user authentication/registration, displaying user playlists, searching tracks. The project also utilizes Redux for global state management and Firebase for backend services.",
      frameworkUsed: "React.js",
      programmingLanguage: "Typescript",
      githubURL: "https://github.com/jurmadani/SpotifyClone",
      projectHighlightImage: SpotifyCloneThumbnail,
      timestamp:"October 2023"
    },
    {
      title: "Whatsapp Clone",
      projectDescription:
        "This project is an WhatsApp clone developed using React Native and TypeScript. It includes features such as user authentication/registration, adding contacts, account editing, send messages/images, all in REAL TIME. The project also utilizes Redux for global state management and Firebase for backend services, including Firebase Storage for storing media files, Firestore for the database.",
      frameworkUsed: "React Native",
      programmingLanguage: "Typescript",
      githubURL: "https://github.com/jurmadani/WhatsAppClone",
      projectHighlightImage: WhatsappCloneThumbnail,
      timestamp: "June 2023",
    },
    {
      title: "Instagram Clone",
      projectDescription:
        "This project is an Instagram clone developed using React Native and TypeScript. It includes features such as user authentication, post sharing, account editing, user search, following/unfollowing other users, and liking/commenting on posts. The project also utilizes Redux for global state management and Firebase for backend services, including Firebase Storage for storing media files, Firestore for the database, and Firebase Authentication for user authentication.",
      frameworkUsed: "React Native",
      programmingLanguage: "Typescript",
      githubURL: "https://github.com/jurmadani/InstagramClone",
      projectHighlightImage: InstagramCloneThumbnail,
      timestamp:"May 2023",
    },
    {
      title: "Hazzard Studio",
      projectDescription:
        "Introducing the new and improved Hazzard Studio mobile app! With our latest update, you can now create your own account and easily keep track of your upcoming appointments at our barbershop. No more missed appointments or double bookings. Plus, you can now pay for your services directly through the app using your credit card. It's never been easier to stay sharp and well-groomed. Download the new Hazzard Studio app today and experience the ultimate convenience in barber services.",
      frameworkUsed: "React Native",
      programmingLanguage: "Typescript",
      githubURL: "https://github.com/jurmadani/HazzardStudio",
      projectHighlightImage: HazzardStudioThumbnail,
      timestamp:"April 2023",
    },
    {
      title: "Orar UPT - April 2023",
      projectDescription:
        "OrarUPT is a cross-platform mobile application built using React Native, specifically designed for students at Universitatea Politehnica Timisoara. The app allows students to create an account using their student email or connect with their Google, Facebook, or Apple credentials. The backend of the application is powered by Google's Firebase, utilizing Firestore database and cloud storage to store user information and profile pictures uploaded within the app.",
      frameworkUsed: "React Native",
      programmingLanguage: "Javascript",
      githubURL: "https://github.com/jurmadani/OrarUPT",
      projectHighlightImage: OrarUPTThumbnail,
      timestamp:"March 2023"
    },
    {
      title: "Chat Sharp",
      projectDescription:
        "ChatSharp is a freeware chat application, with no nags, no ads, built with C# WPF, using .NET 7.0 framework and Visual Studio 2022. It allows users to chat with one another, create a profile, and verify their email address. The application uses Google's Firebase Backend as a Service for user authentication and Firebase's Realtime-Database to store user messages.",
      frameworkUsed: ".NET",
      programmingLanguage: "C#",
      githubURL: "https://github.com/jurmadani/ChatsSharp",
      projectHighlightImage: ChatSharpThumbnail,
      timestamp:"January 2023"
    },
  ];

  return (
    <div className="projects">
      <div className="content">
        {projects.map((project) => (
          <ProjectCard
            title={project.title}
            projectDescription={project.projectDescription}
            frameworkUsed={project.frameworkUsed}
            programmingLanguage={project.programmingLanguage}
            githubURL={project.githubURL}
            projectHighlightImage={project.projectHighlightImage}
            timestamp={project.timestamp}
          />
        ))}
      </div>
    </div>
  );
}
