import React from "react";
import "../css/compStyles/_feed.scss";
import DanielAtDeskImage from "../assets/feed-post-image.png";

export default function Feed() {
  return (
    <div className="feed-grid">
      <article className="feed-card">
        <div className="feed-card-pinned-tag">
          {/* Pin icon  */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="pin-icon"
          >
            <path
              fill="currentColor"
              d="M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12Z"
            ></path>
          </svg>
          {/* Text */}
          <p>Pinned post</p>
        </div>
        <div className="feed-card-user">
          {/* Profile picture */}
          <div className="profile-picture"></div>
          {/* Username */}
          <h4>Jurma Daniel</h4>
          {/* Post date */}
          <span>•</span>
          <p>3 Nov 2023</p>
        </div>
        <div className="feed-card-post-description">
          {/* Title */}
          <h2>Hello World!</h2>
          {/* Description */}
          <p>
            Welcome to my site folks! I've gathered my quirky collection of
            creative projects to share with you. Whether you're a fellow
            creative soul or just someone with wild ideas, get in touch. We can
            turn those crazy concepts into reality. Take a peek at my work, and
            let's start building awesome things together ✨.
          </p>
        </div>
        <div className="feed-card-post-image">
          {/* Image */}
          <img
            src={DanielAtDeskImage}
            alt="daniel at desk selfie"
            loading="eager"
          />
    
        </div>
        <div className="feed-card-post-actions">
          {/* Like button */}
          <div id="main-content">
            <div>
              <input type="checkbox" id="checkbox" />
              <label htmlFor="checkbox">
                <svg
                  id="heart-svg"
                  viewBox="467 392 58 57"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    id="Group"
                    fill="none"
                    fill-rule="evenodd"
                    transform="translate(467 392)"
                  >
                    <path
                      d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z"
                      id="heart"
                      fill="#AAB8C2"
                    />
                    <circle
                      id="main-circ"
                      fill="#E2264D"
                      opacity="0"
                      cx="29.5"
                      cy="29.5"
                      r="1.5"
                    />

                    <g id="grp7" opacity="0" transform="translate(7 6)">
                      <circle id="oval1" fill="#9CD8C3" cx="2" cy="6" r="2" />
                      <circle id="oval2" fill="#8CE8C3" cx="5" cy="2" r="2" />
                    </g>

                    <g id="grp6" opacity="0" transform="translate(0 28)">
                      <circle id="oval1" fill="#CC8EF5" cx="2" cy="7" r="2" />
                      <circle id="oval2" fill="#91D2FA" cx="3" cy="2" r="2" />
                    </g>

                    <g id="grp3" opacity="0" transform="translate(52 28)">
                      <circle id="oval2" fill="#9CD8C3" cx="2" cy="7" r="2" />
                      <circle id="oval1" fill="#8CE8C3" cx="4" cy="2" r="2" />
                    </g>

                    <g id="grp2" opacity="0" transform="translate(44 6)">
                      <circle id="oval2" fill="#CC8EF5" cx="5" cy="6" r="2" />
                      <circle id="oval1" fill="#CC8EF5" cx="2" cy="2" r="2" />
                    </g>

                    <g id="grp5" opacity="0" transform="translate(14 50)">
                      <circle id="oval1" fill="#91D2FA" cx="6" cy="5" r="2" />
                      <circle id="oval2" fill="#91D2FA" cx="2" cy="2" r="2" />
                    </g>

                    <g id="grp4" opacity="0" transform="translate(35 50)">
                      <circle id="oval1" fill="#F48EA7" cx="6" cy="5" r="2" />
                      <circle id="oval2" fill="#F48EA7" cx="2" cy="2" r="2" />
                    </g>

                    <g id="grp1" opacity="0" transform="translate(24)">
                      <circle id="oval1" fill="#9FC7FA" cx="2.5" cy="3" r="2" />
                      <circle id="oval2" fill="#9FC7FA" cx="7.5" cy="2" r="2" />
                    </g>
                  </g>
                </svg>
              </label>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
}
